import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 626.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,626.000000) scale(0.100000,-0.100000)">

<path d="M5725 4252 c-13 -3 -15 -9 -9 -25 10 -28 18 -522 9 -556 l-7 -26 -13
25 c-11 22 -81 265 -115 400 -6 25 -21 75 -32 113 l-20 67 -109 0 -110 0 7
-34 c4 -27 -8 -79 -56 -228 -33 -106 -75 -231 -91 -278 -17 -47 -33 -93 -36
-102 -5 -16 3 -18 66 -18 l71 0 0 43 c0 62 18 117 38 118 9 0 31 3 47 6 17 2
48 2 71 -2 39 -6 41 -7 52 -55 6 -27 9 -63 6 -80 l-6 -30 177 0 177 0 -6 23
c-12 37 -19 269 -11 341 l7 67 71 -213 72 -213 117 -3 117 -3 4 308 c2 169 7
318 11 331 8 21 6 22 -55 22 l-64 0 2 -207 c1 -115 0 -203 -3 -198 -3 6 -36
98 -72 205 l-67 195 -111 5 c-62 3 -119 3 -129 2z m-197 -29 c6 -10 40 -128
77 -263 36 -135 75 -269 85 -298 13 -40 14 -55 5 -58 -6 -3 -50 -4 -96 -2
l-84 3 -2 30 c-2 17 -8 54 -14 83 l-10 52 -98 0 -98 0 -18 -65 c-9 -36 -15
-72 -11 -80 6 -16 3 -16 -61 -17 -28 0 -37 3 -34 13 2 8 44 140 93 293 49 153
87 287 85 297 -6 24 4 27 95 28 64 1 79 -2 86 -16z m395 12 l34 -7 84 -251
c97 -291 93 -294 87 49 l-3 211 38 -2 39 -2 -2 -309 c0 -169 -3 -310 -6 -313
-3 -3 -46 -7 -97 -9 -70 -3 -95 -1 -104 9 -6 8 -46 118 -89 244 -42 127 -81
234 -86 240 -5 6 -8 -93 -8 -240 l1 -250 -35 0 -36 0 0 310 c-1 171 0 313 0
316 0 9 142 11 183 4z"/>
<path d="M5386 4059 c-2 -8 -14 -45 -27 -84 -12 -38 -24 -76 -27 -82 -3 -10
13 -13 58 -13 60 0 62 1 56 23 -3 12 -14 51 -22 87 -18 69 -30 93 -38 69z m43
-146 c1 -9 -13 -13 -38 -13 l-40 0 10 33 c6 17 16 45 22 61 l11 29 17 -49 c10
-27 18 -55 18 -61z"/>
<path d="M8378 4249 c-6 -4 -8 -18 -5 -30 4 -14 -3 -55 -17 -99 -14 -41 -48
-149 -76 -240 -57 -184 -82 -250 -92 -250 -16 0 -20 83 -17 345 l4 275 -100 0
-100 0 0 -330 0 -330 175 2 175 3 -3 30 c-4 32 16 112 29 121 16 10 99 16 132
10 26 -5 36 -13 46 -42 7 -19 11 -54 9 -77 l-3 -42 118 -3 c129 -3 133 -1 102
59 -9 16 -26 71 -39 122 -13 50 -40 146 -59 212 -19 66 -42 152 -52 192 l-17
72 -100 3 c-55 2 -105 0 -110 -3z m-217 -329 l0 -315 -81 0 -81 0 0 310 c0
171 0 314 1 318 0 4 36 7 80 5 l81 -3 0 -315z m405 312 c10 -6 79 -243 146
-502 11 -41 26 -85 34 -97 7 -12 10 -24 6 -26 -4 -3 -50 -4 -102 -4 -92 2 -95
3 -92 24 2 12 -4 50 -14 83 l-17 60 -94 0 c-93 0 -94 0 -104 -27 -6 -15 -13
-53 -17 -83 l-7 -55 -50 0 c-28 0 -49 4 -48 10 1 6 14 40 28 78 51 137 159
493 157 520 l-1 27 82 0 c45 0 87 -4 93 -8z"/>
<path d="M8407 3982 c-15 -49 -27 -92 -27 -95 0 -4 27 -7 61 -7 48 0 60 3 56
14 -3 7 -15 47 -27 87 -12 41 -25 78 -29 82 -4 4 -20 -32 -34 -81z m56 -29
l15 -53 -39 0 c-21 0 -39 3 -39 7 0 21 35 112 41 106 4 -5 13 -32 22 -60z"/>
<path d="M4675 3921 l0 -330 120 -3 c66 -2 158 -1 203 2 76 5 86 8 107 32 38
44 48 120 43 332 -7 299 -4 296 -280 296 l-193 0 0 -329z m418 285 c35 -32 39
-72 35 -321 -4 -291 10 -276 -271 -279 l-162 -1 -3 305 c-2 167 -6 308 -8 312
-3 4 82 8 189 8 191 0 195 0 220 -24z"/>
<path d="M4850 3932 c0 -109 2 -205 5 -213 4 -11 18 -13 58 -9 l52 5 0 205 0
205 -57 3 -57 3 -1 -199z m104 156 c3 -13 6 -93 6 -179 0 -178 -6 -196 -58
-184 l-32 7 0 189 0 189 39 0 c32 0 40 -4 45 -22z"/>
<path d="M6510 4248 c0 -2 -1 -150 -3 -331 l-2 -327 108 0 109 0 -7 30 c-9 39
-14 183 -7 215 4 22 9 25 50 25 l45 0 -5 -135 -5 -135 169 0 170 0 -5 33 c-6
45 19 127 41 128 9 1 28 3 42 4 14 1 45 0 69 -4 42 -6 45 -8 58 -51 7 -25 11
-60 8 -77 l-6 -33 247 0 c356 0 336 -22 342 383 l4 277 -103 0 -104 0 3 -252
c3 -232 2 -253 -14 -265 -22 -16 -80 -17 -139 -1 -45 12 -46 13 -65 78 -24 81
-120 430 -120 436 0 2 -50 4 -110 4 l-111 0 6 -32 c4 -23 -6 -71 -34 -158 -22
-69 -55 -175 -74 -235 -39 -124 -63 -185 -72 -185 -3 0 -5 136 -4 303 l2 302
-101 3 -101 3 6 -126 6 -125 -46 0 -47 0 0 125 0 125 -100 0 c-55 0 -100 -1
-100 -2z m176 -17 c8 -1 9 -40 6 -126 l-5 -125 62 0 62 0 -2 128 -2 127 86 1
87 2 0 -319 0 -320 -86 3 -86 3 1 138 2 138 -58 -3 -58 -3 0 -135 0 -135 -83
0 -83 0 0 313 c0 171 1 314 1 316 0 3 128 1 156 -3z m692 -8 c5 -10 35 -112
67 -228 31 -115 66 -243 77 -282 21 -77 45 -101 33 -35 l-6 37 90 -3 c84 -4
90 -3 101 18 8 16 11 91 8 261 -4 276 -13 253 102 247 l64 -3 -3 -265 c-3
-287 -7 -314 -57 -347 -25 -16 -56 -18 -261 -21 l-233 -4 0 41 c0 22 -5 61
-12 86 l-12 45 -98 0 -97 0 -12 -42 c-7 -24 -13 -61 -14 -83 l-3 -40 -52 -3
c-47 -3 -52 -1 -47 15 3 10 28 86 56 168 94 284 132 409 126 423 -10 26 6 32
90 32 70 0 86 -3 93 -17z"/>
<path d="M7237 4060 c-8 -19 -57 -174 -57 -177 0 -2 27 -3 60 -3 69 0 67 -11
25 121 -13 42 -25 68 -28 59z m37 -132 c6 -27 5 -28 -33 -28 l-39 0 14 48 c24
78 25 80 39 42 6 -19 15 -47 19 -62z"/>
<path d="M4800 3200 l0 -210 116 0 c63 0 124 5 135 11 15 7 24 7 34 -1 7 -6
31 -10 52 -8 36 3 38 5 45 46 10 58 14 62 58 62 44 0 52 -9 60 -65 l5 -40 64
-3 c35 -2 68 1 73 6 5 5 -6 59 -26 128 -18 65 -44 156 -56 202 l-22 82 -69 0
-69 0 0 -28 c0 -16 -22 -95 -49 -176 l-49 -148 -4 157 c-4 202 2 195 -174 195
l-124 0 0 -210z m253 185 c25 -18 36 -73 37 -175 0 -110 -8 -154 -32 -180 -18
-19 -29 -20 -130 -18 l-110 3 1 190 c0 105 1 191 1 193 0 1 48 2 108 2 76 -1
112 -5 125 -15z m278 -12 c6 -16 28 -94 50 -176 21 -81 43 -156 49 -168 11
-19 9 -20 -51 -17 l-61 3 -4 39 c-5 54 -14 61 -78 58 -59 -4 -64 -9 -70 -70
-3 -22 -8 -28 -36 -30 -23 -3 -31 0 -27 10 17 41 109 327 112 348 2 14 4 26 4
28 1 1 24 2 51 2 46 0 51 -3 61 -27z"/>
<path d="M4915 3318 c-3 -8 -4 -66 -3 -129 l3 -114 32 -3 c41 -4 45 9 41 148
l-3 105 -33 3 c-20 2 -34 -2 -37 -10z m55 -119 c0 -102 -1 -109 -20 -109 -19
0 -20 7 -20 108 0 60 3 111 6 115 3 3 12 3 20 0 11 -4 14 -30 14 -114z"/>
<path d="M5222 3242 l-21 -62 40 0 c38 0 39 1 33 28 -4 15 -12 43 -19 62 l-12
34 -21 -62z m38 -34 c0 -5 -7 -8 -15 -8 -15 0 -20 21 -8 33 7 7 23 -11 23 -25z"/>
<path d="M5417 3404 c-4 -4 -7 -28 -7 -53 l0 -47 41 4 41 4 -3 -158 -4 -159
65 0 65 0 0 155 0 155 35 -1 c44 0 48 6 41 59 l-6 42 -131 3 c-71 1 -133 -1
-137 -4z m263 -39 c0 -34 -1 -35 -40 -35 l-40 0 0 -160 0 -160 -50 0 -50 0 0
160 0 160 -40 0 c-38 0 -40 2 -40 29 0 16 6 32 13 34 6 3 65 6 130 6 l117 1 0
-35z"/>
<path d="M5763 3389 c3 -11 -21 -100 -54 -201 -35 -107 -56 -185 -51 -190 5
-5 27 -8 48 -6 35 3 39 6 37 28 -1 14 3 37 9 53 9 24 16 27 54 27 39 0 46 -3
54 -26 6 -15 9 -38 8 -53 l-3 -26 75 0 c61 0 75 3 73 15 -1 15 -84 305 -104
368 l-11 32 -70 0 c-66 0 -70 -1 -65 -21z m131 -6 c3 -10 26 -91 51 -180 25
-89 48 -169 51 -178 5 -12 -4 -15 -55 -15 -60 0 -61 1 -61 28 0 65 -7 72 -71
72 -55 0 -60 -2 -69 -26 -6 -14 -10 -36 -10 -50 0 -20 -5 -24 -31 -24 -25 0
-30 3 -26 18 2 9 28 89 56 176 28 87 51 167 51 177 0 16 8 19 54 19 43 0 56
-4 60 -17z"/>
<path d="M5788 3237 l-15 -52 30 -3 c16 -2 31 -1 34 2 7 7 -17 95 -27 101 -4
3 -14 -19 -22 -48z m32 -22 c0 -8 -4 -17 -10 -20 -6 -4 -10 5 -10 20 0 15 4
24 10 20 6 -3 10 -12 10 -20z"/>
<path d="M6184 3389 c-15 -17 -20 -39 -22 -118 -4 -132 4 -145 86 -149 49 -2
62 -6 62 -19 0 -14 -11 -15 -76 -10 l-76 6 7 -47 c4 -26 9 -50 12 -54 7 -12
189 -9 217 3 23 11 38 36 50 84 3 11 4 -5 3 -35 l-2 -55 195 0 195 0 -4 75
c-4 88 6 85 34 -8 l21 -67 62 -3 c46 -2 64 1 68 11 3 8 -6 44 -20 81 -23 61
-24 69 -11 90 11 16 16 51 16 112 2 104 2 104 58 -136 l36 -155 67 -3 67 -3
10 38 c67 260 85 323 93 323 5 0 7 -80 6 -177 l-3 -178 64 0 64 0 -1 200 c-1
110 -2 203 -2 208 0 4 -47 7 -105 7 -103 0 -105 0 -105 -23 0 -21 -9 -60 -41
-182 -8 -28 -13 -35 -19 -24 -11 19 -50 181 -50 208 0 19 -5 21 -69 21 -39 0
-73 -5 -76 -10 -4 -7 -12 -7 -25 0 -29 16 -520 13 -521 -2 0 -7 0 -71 -1 -143
0 -97 -2 -114 -7 -69 -8 82 -28 104 -92 104 -26 0 -51 5 -55 11 -4 7 15 10 64
7 79 -3 82 -1 74 65 l-4 37 -113 0 c-101 0 -114 -2 -131 -21z m230 -5 c3 -9 6
-25 6 -37 0 -21 -4 -22 -75 -19 -74 4 -75 3 -75 -21 0 -23 4 -25 59 -29 87 -6
91 -12 91 -129 0 -135 -6 -141 -138 -137 l-97 3 -3 30 -3 30 73 0 c71 0 73 1
73 25 0 23 -4 25 -45 28 -102 6 -115 25 -108 154 7 110 17 118 143 118 75 0
94 -3 99 -16z m270 -6 c12 -46 9 -48 -59 -48 l-65 0 1 -32 c0 -30 1 -31 9 -10
8 19 16 22 70 22 41 0 60 -4 60 -12 1 -7 2 -53 4 -101 3 -86 3 -89 -21 -98
-32 -12 -100 -12 -108 1 -3 5 -3 21 1 36 7 26 9 26 60 21 l54 -6 0 55 0 54
-65 0 c-37 0 -65 -4 -65 -10 0 -6 27 -10 61 -10 l60 0 -3 -32 c-3 -33 -3 -33
-58 -33 l-55 0 0 -50 0 -50 63 1 63 1 -3 -31 -3 -31 -112 -3 -113 -3 0 196 0
195 109 0 c104 0 109 -1 115 -22z m270 11 c29 -14 36 -34 36 -112 0 -37 -6
-69 -17 -87 -15 -26 -15 -32 1 -91 10 -35 20 -69 23 -76 4 -10 -9 -13 -50 -13
l-55 0 -17 73 c-31 127 -55 132 -55 11 l0 -85 -51 3 -50 3 0 188 c0 103 1 189
1 192 0 10 210 5 234 -6z m201 -131 c17 -76 34 -140 37 -143 6 -6 55 167 66
235 7 40 11 45 36 48 33 4 35 23 -18 -178 -63 -236 -49 -210 -111 -210 l-54 0
-15 53 c-9 28 -27 102 -41 164 -14 62 -29 123 -34 136 -13 33 -5 38 53 35 l49
-3 32 -137z m298 130 c-2 -5 -3 -91 -3 -193 l0 -185 -50 0 -50 0 0 196 0 195
53 -3 c28 -2 51 -6 50 -10z"/>
<path d="M6820 3285 c0 -46 0 -46 33 -43 30 3 32 5 32 43 0 38 -2 40 -32 43
-33 3 -33 3 -33 -43z m60 0 c0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 5
25 25 25 20 0 25 -5 25 -25z"/>
<path d="M7509 3384 c-22 -24 -24 -34 -27 -157 -4 -150 8 -204 48 -224 32 -16
460 -18 460 -3 0 7 7 7 21 -1 13 -6 59 -10 121 -7 133 4 138 10 138 152 0 131
-9 146 -85 146 -30 0 -57 5 -60 10 -4 6 22 10 69 10 83 0 83 0 70 69 l-6 31
-113 0 c-112 0 -114 0 -134 -27 l-20 -28 -1 28 0 27 -228 0 -229 0 -24 -26z
m215 -6 c12 -46 9 -48 -59 -48 l-65 0 0 -125 0 -125 25 -6 c14 -4 44 -4 66 0
l40 6 -3 -32 -3 -33 -70 -3 c-152 -7 -158 1 -153 208 4 177 6 180 133 180 77
0 83 -1 89 -22z m250 6 c17 -44 9 -53 -56 -56 -61 -3 -62 -4 -61 -30 2 -63 -1
-60 58 -56 l55 3 0 -35 0 -36 -57 1 -56 0 0 -32 c2 -78 -3 -72 68 -69 53 2 65
0 60 -12 -3 -7 -5 -22 -5 -33 0 -18 -8 -19 -112 -19 l-113 0 2 192 c1 106 2
194 3 196 0 1 47 2 104 2 85 0 105 -3 110 -16z m274 -21 l3 -33 -70 0 c-69 0
-71 -1 -71 -25 0 -22 4 -25 34 -25 68 0 94 -11 105 -43 14 -40 14 -160 0 -191
-14 -31 -33 -36 -141 -36 -64 0 -88 3 -89 13 0 6 -2 22 -5 34 -4 21 -1 22 45
16 71 -8 101 2 101 32 0 14 -3 25 -7 26 -5 0 -32 2 -62 3 -76 3 -83 16 -79
138 4 126 7 129 138 125 l95 -2 3 -32z m-508 -154 c0 -98 0 -100 -26 -109 -14
-6 -43 -10 -65 -10 l-39 0 0 109 c0 63 4 111 10 113 5 2 35 1 65 0 l55 -4 0
-99z m253 80 c6 -24 4 -25 -65 -28 -56 -2 -58 -1 -58 23 0 27 18 34 82 28 25
-3 38 -10 41 -23z m25 -152 c10 -9 41 -17 72 -19 30 -2 55 -7 55 -13 0 -6 -53
-10 -137 -11 l-138 -1 0 35 0 35 55 -6 c53 -5 55 -5 62 21 l8 27 3 -26 c2 -15
11 -34 20 -42z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
